import classes from "./CardBlockSecond.module.css"
import "@fontsource/roboto"
import { useState, useRef } from 'react'
import { useFetching } from "components/hooks/useFetching"
import Strings from "values/Strings"
import Loader from "components/UI/loader/Loader"
import Surgu from "assets/images/company/surgu.png"
import School from "assets/images/company/school.png"
import Energ from "assets/images/company/energosbit.png"
import Prior from "assets/images/company/prior.png"
import Week from "assets/images/company/week.png"
import Adm from "assets/images/companyOriginal/hmao.svg"
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/effect-cards';
import 'styles/css/card2.css';
import { Autoplay, EffectCards } from 'swiper/modules';

const CardBlockSecond = ({ onClick }) => {


    return (
        <Loader>
            <div>
                <Swiper
                    effect={'cards'}
                    grabCursor={true}

                    autoplay={{
                        delay: 1500,
                        disableOnInteraction: false,
                    }}


                    modules={[Autoplay, EffectCards]}
                    className="mySwiper"
                >
                    <SwiperSlide className={"card__style1"} onClick={() => window.open("https://www.surgu.ru/index")}>
                        <div >
                            <img src={Surgu} alt="" className={classes.img__style} />
                            <p className={classes.text__style}>
                                Сургутский <br />
                                государственный<br />
                                университет<br />
                            </p>
                            <p className={classes.description__style}>
                                Думать. Действовать.<br />Достигать.
                            </p>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide className={"card__style2"} onClick={() => window.open("https://21-school.ru/")}>
                        <div>
                            <img src={School} alt="" className={classes.img__style} />
                            <p className={classes.text__style}>
                                Школа 21
                            </p>
                            <p className={classes.description__style}>
                                Программирование<br />с нуля для тех,<br />кто хочет
                            </p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className={"card__style2"} onClick={() => window.open("https://admhmansy.ru/news/480/177934/")}>
                        <div>
                            <img src={Adm} alt="" className={classes.img__style} />
                            <p className={classes.text__style}>
                                Департамент
                            </p>
                            <p className={classes.description__style}>
                                информационных технологий<br />и цифрового развития<br />Ханты-Мансийского<br />автономного округа-Югры
                            </p>
                        </div>
                    </SwiperSlide>

                    {/* <SwiperSlide className={"card__style1"} onClick={() => window.open("https://gesbt.ru/")}>
                        <div>
                            <img src={Energ} alt="" className={classes.img__style} />
                            <p className={classes.text__style}>
                                Газпром<br />энергосбыт<br />
                            </p>
                            <p className={classes.description__style}>
                                Тюмень
                            </p>
                        </div>
                    </SwiperSlide> */}

                    <SwiperSlide className={"card__style2"} onClick={() => window.open("https://priority2030.ru/")}>
                        <div>
                            <img src={Prior} alt="" className={classes.img__style} />
                            <p className={classes.text__style}>
                                Приоритет
                            </p>
                            <p className={classes.description__style}>
                                Программа поддержки<br />вузов
                            </p>
                        </div>
                    </SwiperSlide>

                    {/* <SwiperSlide className={"card__style2"} onClick={() => window.open("https://weeek.net/")}>
                        <div>
                            <img src={Week} alt="" className={classes.img__style} />
                            <p className={classes.text__style}>
                                Weeek
                            </p>
                            <p className={classes.description__style}>
                                It's cool to work here
                            </p>
                        </div>
                    </SwiperSlide> */}
                </Swiper>
            </div>
        </Loader>
    )
}

export default CardBlockSecond