import "styles/css/mentor-case.css"
import Loader from "components/UI/loader/Loader"
import SelectCase from "components/UI/mentorCaseProfile/selectCase/SelectCase"
import { useDeviceType } from "components/hooks/useDeviceType";
import { useState, useEffect } from "react";
import OAuthService from "services/OAuthService";
import { useFetching } from "components/hooks/useFetching";
import TaskService from "services/TaskService";
import { NotificationManager } from "react-notifications";
import Strings from "values/Strings";
import TaskStatusService from "services/TaskStatusService";
import CaseContainer from "components/UI/case/caseContainer/CaseContainer";


const MentorCase = () => {

    const device = useDeviceType()
    const [tasks, setTasks] = useState([])


    const notificationError = (error) => {
        if (error.length > 0) {
            NotificationManager.error(JSON.stringify(error), Strings.notificationsErrorTitle, 5000);
        }
    }


    const [fetchingTasks, loadingTasks, errorWhileFetching] = useFetching(async (taskIds) => {
        try {
            const taskStatus = await TaskStatusService.getStatus()
            const res = await TaskService.getTasksForMentorProfile(taskIds.task)
            if (res) {
                setTasks(res.map(item => {
                    return <SelectCase taskStatus={taskStatus} key={item.task} {...item} />
                }))
            }
        } catch (error) {
            notificationError(error?.response?.data?.message ? error?.response?.data?.message : error)
        }
    })


    useEffect(() => {
        const userInfo = OAuthService.decryptedSync('userData')
        if (userInfo?.tasks) {
            fetchingTasks(userInfo.tasks)
        }
    }, [])

    return (
        <Loader>
            {device === "phone"
                ? <div className="mentor-case__first-block-style mentor-case-gap">
                    {tasks.length > 0 ? tasks : <CaseContainer text={Strings.mentorWithOutCase} />}
                </div>
                : <div className="first-block-style">
                    <div className="mentor-case__mentor-case-profie-info-about-case">
                        {tasks.length > 0 ? tasks : <CaseContainer text={Strings.mentorWithOutCase} />}
                    </div>
                </div>
            }
        </Loader>
    )
}

export default MentorCase