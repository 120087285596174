import AuthBlock from "components/UI/authBlock/AuthBlock";
import env from '../../../package.json'

function OAuth({ setTokenInfo, setUserInfo }) {
    const yandexOAuth = () => {
        const urlResponseOauth = `https://oauth.yandex.ru/authorize?response_type=code&client_id=${env.yandexAPI.clientID}&redirect_uri=${env.redirectUri}`
        window.open(urlResponseOauth, '_self')
    }


    // const keyklockOAuth = () => {
    //     const urlResponseOauth = `https://auth.edro.su/realms/master/protocol/openid-connect/auth?client_id=events&redirect_uri=http://localhost:8080/api/oauth/keycloak&response_type=code&scope=openid`;
    //     // window.open(urlResponseOauth, '_self')

    //     // Открываем окно с определенными параметрами
    //     const width = 600;
    //     const height = 700;
    //     const left = (window.screen.width / 2) - (width / 2);
    //     const top = (window.screen.height / 2) - (height / 2);

    //     const popup = window.open(
    //         urlResponseOauth,
    //         'Keycloak Login',
    //         `width=${width},height=${height},top=${top},left=${left},toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,copyhistory=no`
    //     );

    //     // Опционально: Можно добавить слушателя на закрытие окна, если нужно
    //     popup.onunload = () => {
    //         // Здесь можно добавить логику, например, обновить страницу
    //         // или выполнить другой код после закрытия окна
    //     };
    // };

    return (
        <AuthBlock setTokenInfo={setTokenInfo} setUserInfo={setUserInfo}
            // <AuthBlock setTokenInfo={setTokenInfo} setUserInfo={setUserInfo} keyklockOAuth={keyklockOAuth}
            yandexOAuth={yandexOAuth} />
    );
}

export default OAuth;