import classes from "./Answer.module.css"
import { useState } from "react"

const Answer = ({ title, setState, defaultValue = null, ...props }) => {

    const [selectItem, setSelectItem] = useState(defaultValue)

    function onClickButton(value) {
        setState(value)
        setSelectItem(value)
    }

    return (
        <fieldset className={classes.container}>
            <legend className={classes.textLocation__legendStyle}>{title}</legend>
            <div><button style={{ border: selectItem === true && "solid 0.2vw var(--main-color-border)" }} className={classes.textLocation__buttonStyle} onClick={() => onClickButton(true)} >Да</button></div>
            <div><button style={{ border: selectItem === false && "solid 0.2vw var(--main-color-border)" }} className={classes.textLocation__buttonStyle} onClick={() => onClickButton(false)}>Нет</button></div>
        </fieldset >
    )
}

export default Answer