import TeamRoom from "components/UI/teamRoom/TeamRoom"
import Loader from 'components/UI/loader/Loader';
import { useFetching } from 'components/hooks/useFetching';
import Strings from 'values/Strings';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import { useNavigate } from "react-router-dom";
import { useDispatch, } from 'react-redux';
import { useMemo, useState } from 'react';
import TeamRoomService from "services/TeamRoomService";
import CommandService from "services/CommandService";
import { publickRoutNames } from "router";
import TaskService from "services/TaskService";
import OAuthService from "services/OAuthService";
import { useEffect } from "react";
import { newName } from "redux/slice/titleSlice";
import { setSidebarItem } from "redux/slice/sidebarItemSlice";
function TeamRoomPage() {

    const [listParticipants, setListParticipants] = useState([])
    const dispatch = useDispatch()
    const [commandUserInfo, setCommandUserInfo] = useState({})
    const [task, setTask] = useState([])
    const [taskList, setTaskList] = useState([])
    const navigator = useNavigate()
    const [currentTask, setCurrentTask] = useState('')
    const [repository, setRepository] = useState('')
    const [mentorTg, setMentorTg] = useState('')

    const [teamRm, setTeamRm] = useState({
        presentation: '',
        additional_material: '',
        command_link: '',
        repository: '',
        time_managment: '',
        table_number: '',
        id: '',
        tg_link: '',
        task_selectable: true
    })


    const notificationError = (error) => {
        if (error?.message.length > 0) {
            NotificationManager.error(error.message, Strings.notificationsErrorTitle, 5000);
        }
    }

    const [fetchingTasks, loadingFetchingTasks, errorFetchingTasks] = useFetching(async (command) => {
        try {
            const res = await TaskService.getCurrentTasks(command)
            setTask(res)
            setTaskList(res.map(item => item.title))
        } catch (error) {
            notificationError(error)
        }
    })


    const [checkCommand, loadingCommand, errorCommand] = useFetching(async () => {
        try {
            const commandRes = await CommandService.checkCommand()
            commandRes ? localStorage.setItem('command', commandRes) : localStorage.removeItem("command");
            if (commandRes) {
                const { command } = await OAuthService.decrypted('command')
                fetchingTeamRoom(command)
                fetchingTasks(command)
            } else {
                dispatch(newName(Strings.sidebarMain))
                navigator(`/${publickRoutNames.mainInfo}`)
            }
        } catch (error) {
            notificationError(error)
        }
    })

    const [fetchingChoiceTask, loadingChoiceTask, errorChoiceTask] = useFetching(async (commandData = commandUserInfo) => {
        try {
            const res = await TeamRoomService.getChoiceTask(commandData?.command)
            setCurrentTask(res.title)
            setMentorTg(res.contact_tg)
        } catch (error) {
            notificationError(error)
        }
    })


    const [fetchingTeamRm, loadingTeamRm, errorTeamRm] = useFetching(async (teamRm) => {
        try {
            const teamRoomUpdateInfo = await TeamRoomService.updateTeamRoom({ ...teamRm })
            setTeamRm(old => (
                { ...old, presentation: teamRoomUpdateInfo.presentation, additional_material: teamRoomUpdateInfo.additional_material }
            ))
            NotificationManager.success(Strings.notificationsDataUpdate, Strings.notificationsSuccessTitle, 2500);
        } catch (error) {
            notificationError(error)
        }
    })

    const [fetchingTeamRoom, loadingTeamRoom, errorTeamRoom] = useFetching(async (id) => {
        try {
            const { commandInfo } = await TeamRoomService.getTeamRoom(id)
            setRepository(commandInfo[0]?.tg_link)
            await setTeamRm(old => ({
                ...old,
                presentation: commandInfo[0].presentation,
                additional_material: commandInfo[0].additional_material,
                command_link: commandInfo[0].command_link,
                repository: commandInfo[0].repository,
                time_managment: commandInfo[0].time_managment,
                table_number: commandInfo[0].table_number,
                id,
                tg_link: commandInfo[0]?.tg_link,
                task_selectable: commandInfo[0]?.task_selectable
            }))
            dispatch(newName(`${Strings.teamRoom} ${commandInfo[0].command_name}`))
            setListParticipants(commandInfo.map(item => item.user))
        } catch (error) {
            notificationError(error)
        }
    })



    const getCommandInfo = async () => {
        const commandData = OAuthService.decryptedSync('command')
        setCommandUserInfo(commandData)
        dispatch(setSidebarItem(Strings.teamRoom))
        await checkCommand()
        if (commandData?.command) {
            fetchingChoiceTask(commandData)
        }
    }

    const [updateTask, loadingUpdateTask, errorUpdateTask] = useFetching(async (currentTask) => {
        try {
            await TeamRoomService.updateTaskTeamRoom({
                id: currentTask?.id,
                command: commandUserInfo?.command,
                category: currentTask?.category
            })
            fetchingChoiceTask()
            NotificationManager.success(Strings.notificationsDataUpdate, Strings.notificationsSuccessTitle, 2500);
        } catch (error) {
            if (error?.message === "Операция запрещена!") {
                getCommandInfo()
            }
            notificationError(error)
        }
    }, false)

    const choiceTask = (_, i) => {
        setCurrentTask(taskList[i])
        updateTask(task[i])
    }


    useEffect(() => {
        getCommandInfo()
    }, [])




    return (
        <Loader>
            <div className="team-room-container">
                <TeamRoom currentTask={currentTask} repository={repository} mentor={mentorTg} choiceTask={choiceTask} fetchingTeamRm={fetchingTeamRm}
                    task={task} taskList={taskList} teamRm={teamRm} setTeamRm={setTeamRm} loading={loadingTeamRoom}
                    listParticipants={listParticipants} />
            </div>
        </Loader>
    );
}

export default TeamRoomPage;