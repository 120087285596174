import classes from "./Profile.module.css"
import "@fontsource/roboto"
import InputBlock from "components/UI/inputBlock/InputBlock"
import InputWithMaskPhone from "components/UI/inputWithMaskPhone/InputWithMaskPhone"
import Button from "components/UI/buttons/button/Button"
import 'rc-tooltip/assets/bootstrap_white.css';
import { useDeviceType } from "components/hooks/useDeviceType"
import { useRef, useState } from "react"
import { useDispatch } from "react-redux"
import AccountService from "services/AccountService"
import Gender from "model/gender"
import { useFetching } from "components/hooks/useFetching"
import NotificationManager from "react-notifications/lib/NotificationManager"
import Strings from "values/Strings"
import Notifications from "../popupNotifications/Notifications"
import Loader from "../loader/Loader"
import { changeLoaderStatus } from "redux/slice/loaderSlice"
import { useEffect } from "react"
import { privateRoutNames, publickRoutNames } from "router"
import { useNavigate } from "react-router-dom"
import OAuthService from "services/OAuthService"
import { FcDocument } from 'react-icons/fc';
import ProfileImage from "./profileImage/ProfileImage"
import * as EmailValidator from 'email-validator';
import env from '../../../../package.json'
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

import TitleMain from "../titleMain/TitleMain"
import CommandService from "services/CommandService"
import { ClassSharp } from "@mui/icons-material"

const Profile = ({ children, ...props }) => {

    const dispatch = useDispatch()
    const device = useDeviceType()
    const navigate = useNavigate()
    const [userInfo, setUserInfo] = useState({
        birthday: '', educational_institution: '',
        gender: '', group_class: '', account_status: '',
        name: '', patronymic: '', phone: '',
        surname: '', email: '', image_profile: '', check_politics: false
    });


    const [image, setImage] = useState(null)
    const [dragEndDropImage, setDragEndDropImage] = useState("")
    const [url, setUrl] = useState("")


    const notificationError = (error) => {
        if (error.length > 0) {
            NotificationManager.error(error, Strings.notificationsErrorTitle, 5000);
        }
    }

    const notificationWarning = (error) => {
        if (error.length > 0) {
            NotificationManager.warning(error, Strings.notificationsWarningTitle, 5000);
        }
    }

    const isImage = (file) => {
        const allowedTypes = [
            'image/jpg', 'image/jpeg',
            'image/png', 'image/svg',
            'image/gif', 'image/webp',
            'image/flif', 'image/cr2',
            'image/tif', 'image/bmp',
            'image/jxr', 'image/psd',
            'image/ico', 'image/bpg',
            'image/jp2', 'image/jpm',
            'image/jpx', 'image/heic',
            'image/cur', 'image/dcm'];
        return allowedTypes.includes(file.type);
    }

    const [updateAccount, loadingAccount, errorAccount] = useFetching(async (userInfo) => {
        try {
            // if (!image && (userInfo?.image_profile === "https://yadi.sk/i/JR3WCTUBN6Ri0g" || userInfo?.image_profile === '')) {
            //     notificationWarning('Загрузите пожалуйста фотографию!')
            // } else
            // console.log('userInfo?.birthday :', new Date(userInfo?.birthday));
            if (userInfo?.name === '' || userInfo?.surname === '') {
                notificationWarning('Поля "Фамилия" и "Имя" не могут быть пустыми!')
            } else if (userInfo?.gender === '' || userInfo?.gender === 3) {
                notificationWarning('Пол не выбран!')
            } else if (!userInfo?.birthday || userInfo?.birthday === '') {
                notificationWarning('Поле "Дата рождения" пустое!')
            } else if (userInfo?.account_status === '' || userInfo?.account_status === 3) {
                notificationWarning('"Статус" не выбран!')
            } else if (userInfo?.educational_institution === '') {
                notificationWarning(`"${accountStatusList[userInfo.account_status]?.organizationName}" не выбран!`)
            } else if (userInfo?.group_class === '') {
                notificationWarning(`"${accountStatusList[userInfo.account_status]?.group_class}" не выбран!`)
            } else if (userInfo?.email === '') {
                notificationWarning('Поле "Почта" пустое!')
            } else if (userInfo?.phone === '') {
                notificationWarning('"Номер телефона" пустое!')
            }
            else if (userInfo?.check_politics === false) {
                NotificationManager.warning(Strings.notificationsPoliticsTitle, Strings.notificationsInfoTitle, 5000);
            }
            else {
                const imageFile = image ? image.get('file') : false
                const validImageFile = isImage(imageFile)
                if (imageFile && !validImageFile) {
                    notificationError("Неверный формат изображения!")
                } else if (!EmailValidator.validate(userInfo?.email)) {
                    notificationError("Неверный формат почты!")
                } else {
                    const res = await AccountService.updateAccount(imageFile
                        ? { ...userInfo, file: imageFile }
                        : userInfo)
                    setUserInfo(res)
                    dispatch(changeLoaderStatus(false))
                    const occasionId = await localStorage.getItem('urlCreateTask')
                    const urlReg = await localStorage.getItem('urlReg')
                    occasionId && navigate(`/private/${privateRoutNames.createCase}`)
                    if (urlReg) {
                        try {
                            const res = await OAuthService.refreshAuth()
                            const userData = res?.userData
                            localStorage.setItem('accessToken', res.accessToken)
                            userData ? localStorage.setItem('userData', userData) : localStorage.removeItem("userData");
                            const commandRes = await CommandService.checkCommand()
                            commandRes ? localStorage.setItem('command', commandRes) : localStorage.removeItem("command");
                        } catch (error) {
                            localStorage.removeItem("userData")
                            localStorage.removeItem("command")
                        }
                        const { tokenType, token } = JSON.parse(urlReg)
                        navigate(`/main/info?${tokenType}=${token}`)
                    }
                    await NotificationManager.success(Strings.notificationsDataUpdate, Strings.notificationsSuccessTitle, 2500);
                }
            }
        } catch (error) {
            notificationError(JSON.stringify(JSON.parse(error.request.responseText).message))
        }
    })


    const [fetchAccountInfo, loadingAccountInfo, errorAccountInfo] = useFetching(async (id) => {
        try {
            const res = await AccountService.getAccount(id)
            setUserInfo(res)
            dispatch(changeLoaderStatus(false))
        } catch (error) {
            notificationError(JSON.stringify(error))
        }
    })

    useEffect(() => {
        const { user } = OAuthService.decryptedSync('userData')
        if (user?.id) {
            fetchAccountInfo(user?.id)
        } else {
            navigate(`/${publickRoutNames.main}`, { state: { auth: true } })
        }
    }, [])



    const accountStatusList = [
        {
            id: "radio-pa", account_status: 0, title: 'Партнер',
            organizationName: 'Организация', group_class: "Подразделение"
        },
        {
            id: "radio-sc", account_status: 1, title: 'Студент',
            organizationName: 'Вуз', group_class: "Группа"
        },
        {
            id: "radio-st", account_status: 2, title: 'Школьник',
            organizationName: 'Школа', group_class: "Класс"
        },
    ]


    useEffect(() => {
        // делаем запрос в яндекс и получеаем данные об файле
        if (userInfo?.image_profile) {
            fetch(`https://cloud-api.yandex.net/v1/disk/public/resources?public_key=${userInfo?.image_profile}`).then(responseApi => responseApi.json())
                // убераем из url файла данные об его размере
                .then(data => data.preview?.split("size")[0])
                //  добавляем в url файла свои данные об размере
                .then(responseUrl => `${responseUrl}size=512x512`)
                // делаем запрос для получение изображения и ждем пока все пакеты приду
                .then(newUrl => fetch(newUrl)).then(response => response.blob())
                // формируем локалный url для фотографии и добавляем его в хук для дальнейшего отображения
                .then(image => {
                    setUrl(URL.createObjectURL(image))
                    setDragEndDropImage(<img src={URL.createObjectURL(image)} style={{ objectFit: 'cover', }} />)
                })
        }
    }, [userInfo?.image_profile])


    return (
        <>
            <div className={classes.profile__image}>
                <ProfileImage style={{ objectFit: 'cover', cursor: "pointer" }}
                    image={image} setImage={(setImage)} url={url}
                    dragEndDropImage={dragEndDropImage} setDragEndDropImage={setDragEndDropImage}
                />
                <p className={classes.style__text__image}>Пожалуйста, загружайте фото <span style={{ color: "red" }}>вашего лица в хорошем качестве.</span> Изображения будут использоваться для входа на мероприятие через систему распознавания лиц!</p>
            </div>
            <Loader>
                <div className={classes.profile__style__main}>
                    <ul>
                        {device === "phone"
                            ? <li>
                                <InputBlock placeholder={"Фамилия"} setValue={char => setUserInfo(old => ({ ...old, surname: char }))} value={userInfo?.surname} onListStatus={false}><img alt="" src={require("assets/icon/pencil.jpg")} /></InputBlock>
                            </li>
                            : <li>
                                {/* <Tooltip placement="top" trigger={'hover'} overlay={<span style={{ fontSize: "0.7vw", fontFamily: "Roboto" }} className={classes.tooltip__style}>В данном поле <br />заполняется фамилия участника</span>}>
                                    <IconWithText text={"Фамилия"}><img alt="" src={require("assets/icon/user.png")} /></IconWithText>
                                </Tooltip> */}

                                <InputBlock placeholder={"Фамилия"} setValue={char => setUserInfo(old => ({ ...old, surname: char }))} value={userInfo?.surname} onListStatus={false}><img alt="" src={require("assets/icon/pencil.jpg")} /></InputBlock>
                            </li>
                        }

                        {device === "phone"
                            ? <li>
                                <InputBlock setValue={char => setUserInfo(old => ({ ...old, name: char }))} value={userInfo?.name} placeholder={"Имя"} onListStatus={false}><img alt="" src={require("assets/icon/pencil.jpg")} /></InputBlock>
                            </li>
                            : <li>
                                {/* <Tooltip placement="top" trigger={'hover'} overlay={<span style={{ fontSize: "0.7vw", fontFamily: "Roboto" }} className={classes.tooltip__style}>В данном поле <br />заполняется фамилия участника</span>}>
                                    <IconWithText text={"Имя"}><img alt="" src={require("assets/icon/user.png")} /></IconWithText>
                                </Tooltip> */}

                                <InputBlock placeholder={"Имя"} setValue={char => setUserInfo(old => ({ ...old, name: char }))} value={userInfo?.name} onListStatus={false}><img alt="" src={require("assets/icon/pencil.jpg")} /></InputBlock>
                            </li>
                        }

                        {device === "phone"
                            ? <li>
                                <InputBlock setValue={char => setUserInfo(old => ({ ...old, patronymic: char }))} value={userInfo?.patronymic} placeholder={"Отчество"} onListStatus={false}><img alt="" src={require("assets/icon/pencil.jpg")} /></InputBlock>
                            </li>
                            : <li>
                                {/* <Tooltip placement="top" trigger={'hover'} overlay={<span style={{ fontSize: "0.7vw", fontFamily: "Roboto" }} className={classes.tooltip__style}>В данном поле <br />заполняется фамилия участника</span>}>
                                    <IconWithText text={"Отчество"}><img alt="" src={require("assets/icon/user.png")} /></IconWithText>
                                </Tooltip> */}

                                <InputBlock placeholder={"Отчество"} setValue={char => setUserInfo(old => ({ ...old, patronymic: char }))} value={userInfo?.patronymic} onListStatus={false}><img alt="" src={require("assets/icon/pencil.jpg")} /></InputBlock>
                            </li>
                        }

                        {device === "phone"
                            ? <li>
                                <div className={classes.input__block}>
                                    <div className={classes.form_radio_btn}>
                                        <input id="radio-1" type="radio" name="gender" checked={Gender.male.id === userInfo.gender} value={Gender.male.id} onChange={char => setUserInfo(old => ({ ...old, gender: parseInt(char.target.value) }))} />
                                        <label htmlFor="radio-1">Мужчина</label>
                                    </div>
                                    <div className={`${classes.form_radio_btn} ${classes.style__radio}`} style={{ marginLeft: "2vw" }}>
                                        <input id="radio-2" type="radio" name="gender" checked={Gender.female.id === userInfo.gender} value={Gender.female.id} onChange={char => setUserInfo(old => ({ ...old, gender: parseInt(char.target.value) }))} />
                                        <label htmlFor="radio-2">Женщина</label>
                                    </div>
                                </div>
                            </li>
                            : <li>
                                {/* <Tooltip placement="top" trigger={'hover'} overlay={<span style={{ fontSize: "0.7vw", fontFamily: "Roboto" }} className={classes.tooltip__style}>В данном поле, вы выбираете<br />свой пол согласно документам</span>}>
                                    <IconWithText text={"Пол"}><img alt="" src={require("assets/icon/gender.png")} /></IconWithText>
                                </Tooltip> */}
                                <div className={classes.input__block}>
                                    <div className={classes.form_radio_btn}>
                                        <input id="radio-1" type="radio" name="gender" checked={Gender.male.id === userInfo.gender} value={Gender.male.id} onChange={char => setUserInfo(old => ({ ...old, gender: parseInt(char.target.value) }))} />
                                        <label htmlFor="radio-1">Мужчина</label>
                                    </div>
                                    <div className={`${classes.form_radio_btn} ${classes.style__radio}`} style={{ marginLeft: "2vw" }}>
                                        <input id="radio-2" type="radio" name="gender" checked={Gender.female.id === userInfo.gender} value={Gender.female.id} onChange={char => setUserInfo(old => ({ ...old, gender: parseInt(char.target.value) }))} />
                                        <label htmlFor="radio-2">Женщина</label>
                                    </div>
                                </div>
                            </li>
                        }

                        {device === "phone"
                            ? <li>

                                <InputBlock type="date" placeholder={"Возраст"} value={userInfo.birthday} setValue={char => setUserInfo(old => ({ ...old, birthday: char }))} onListStatus={false}><img src={require("assets/icon/calendar.jpg")} /></InputBlock>
                            </li>
                            : <li>
                                {/* <Tooltip placement="top" trigger={'hover'} overlay={<span style={{ fontSize: "0.7vw", fontFamily: "Roboto" }} className={classes.tooltip__style}>В данном поле, вы выбираете<br />дату своего рождения в календаре</span>}>
                                    <IconWithText text={"Возраст"}><img alt="" src={require("assets/icon/adult.png")} /></IconWithText>
                                </Tooltip> */}

                                <InputBlock placeholder={"Возраст"} type="date" onListStatus={false} value={userInfo.birthday} setValue={char => setUserInfo(old => ({ ...old, birthday: char }))}><img src={require("assets/icon/calendar.jpg")} /></InputBlock>
                            </li>
                        }


                        {device === "phone"
                            ? <li>
                                <div className={classes.input__block}>
                                    {accountStatusList.map((item, i) => {
                                        return (
                                            <div key={i} className={classes.form_radio_btn}>
                                                <input id={item.id} type="radio" name="scst" value={item.account_status} checked={userInfo.account_status === item.account_status} onChange={char => setUserInfo(old => ({ ...old, account_status: parseInt(char.target.value) }))} />
                                                <label htmlFor={item.id}>{item.title}</label>
                                            </div>
                                        )
                                    })}
                                </div>
                            </li>
                            : <li>
                                {/* <Tooltip placement="top" trigger={'hover'} overlay={<span style={{ fontSize: "0.7vw", fontFamily: "Roboto" }} className={classes.tooltip__style}>В данном поле, вы выбираете<br />свой статус обучения</span>}>
                                    <IconWithText text={"Статус"}><img alt="" src={require("assets/icon/coding.png")} /></IconWithText>
                                </Tooltip> */}
                                <div className={classes.input__block}>
                                    {accountStatusList.map((item, i) => {
                                        return (
                                            <div key={i} className={classes.form_radio_btn}>
                                                <input id={item.id} type="radio" name="scst" value={item.account_status} checked={userInfo.account_status === item.account_status} onChange={char => setUserInfo(old => ({ ...old, account_status: parseInt(char.target.value) }))} />
                                                <label htmlFor={item.id}>{item.title}</label>
                                            </div>
                                        )
                                    })}
                                </div>
                            </li>
                        }


                        {device === "phone"
                            ? <li>
                                <InputBlock setValue={char => setUserInfo(old => ({ ...old, educational_institution: char }))} value={userInfo?.educational_institution} placeholder={accountStatusList[userInfo.account_status]?.organizationName} onListStatus={false}><img alt="" src={require("assets/icon/pencil.jpg")} /></InputBlock>
                            </li>
                            : <li>
                                {/* <Tooltip placement="top" trigger={'hover'} overlay={<span style={{ fontSize: "0.7vw", fontFamily: "Roboto" }} className={classes.tooltip__style}>В данном поле пишется краткое<br /> или полное название организации,<br />где вы обучаетесь/работаете</span>}>
                                    <IconWithText text={accountStatusList[userInfo.account_status]?.organizationName}><img alt="" src={require("assets/icon/school.png")} /></IconWithText>
                                </Tooltip> */}

                                <InputBlock placeholder={accountStatusList[userInfo.account_status]?.organizationName} setValue={char => setUserInfo(old => ({ ...old, educational_institution: char }))} value={userInfo?.educational_institution} onListStatus={false}><img alt="" src={require("assets/icon/pencil.jpg")} /></InputBlock>
                            </li>
                        }

                        {device === "phone"
                            ? <li>
                                <InputBlock value={userInfo?.group_class} setValue={char => setUserInfo(old => ({ ...old, group_class: char }))} placeholder={accountStatusList[userInfo.account_status]?.group_class} onListStatus={false}><img alt="" src={require("assets/icon/pencil.jpg")} /></InputBlock>
                            </li>
                            : <li>
                                {/* <Tooltip placement="top" trigger={'hover'} overlay={<span style={{ fontSize: "0.7vw", fontFamily: "Roboto" }} className={classes.tooltip__style}>В данном поле пишется краткое<br /> или полное название группы/класса/офиса</span>}>
                                    <IconWithText text={accountStatusList[userInfo.account_status]?.group_class}><img alt="" src={require("assets/icon/people.png")} /></IconWithText>
                                </Tooltip> */}

                                <InputBlock placeholder={accountStatusList[userInfo.account_status]?.group_class} value={userInfo?.group_class} setValue={char => setUserInfo(old => ({ ...old, group_class: char }))} onListStatus={false}><img alt="" src={require("assets/icon/pencil.jpg")} /></InputBlock>
                            </li>
                        }

                        {device === "phone"
                            ? <li>
                                <InputBlock value={userInfo?.email} setValue={char => setUserInfo(old => ({ ...old, email: char }))} placeholder={"Почта"} onListStatus={false}><img alt="" src={require("assets/icon/pencil.jpg")} /></InputBlock>
                            </li>
                            : <li>
                                {/* <Tooltip placement="top" trigger={'hover'} overlay={<span style={{ fontSize: "0.7vw", fontFamily: "Roboto" }} className={classes.tooltip__style}>В данном поле указывается ваш<br /> почтовый адрес для обратной связи</span>}>
                                    <IconWithText text={"Почта"}><img alt="" src={require("assets/icon/gmail.png")} /></IconWithText>
                                </Tooltip> */}

                                <InputBlock placeholder={"Почта"} value={userInfo?.email} setValue={char => setUserInfo(old => ({ ...old, email: char }))} onListStatus={false}><img alt="" src={require("assets/icon/pencil.jpg")} /></InputBlock>
                            </li>
                        }

                        {device === "phone"
                            ? <li>
                                <InputWithMaskPhone placeholder={"Телефон"} setValuePhone={char => setUserInfo(old => ({ ...old, phone: char }))} valuePhone={userInfo?.phone} onListStatus={false}><img alt="" src={require("assets/icon/pencil.jpg")} /></InputWithMaskPhone>
                            </li>
                            : <li>
                                {/* <Tooltip placement="top" trigger={'hover'} overlay={<span style={{ fontSize: "0.7vw", fontFamily: "Roboto" }} className={classes.tooltip__style}>В данном поле указывается ваш<br /> номер телефона для обратной связи</span>}>
                                    <IconWithText text={"Телефон"}><img alt="" src={require("assets/icon/smartphone.png")} /></IconWithText>
                                </Tooltip> */}

                                <InputWithMaskPhone placeholder={"Телефон"} setValuePhone={char => setUserInfo(old => ({ ...old, phone: char }))} valuePhone={userInfo?.phone} onListStatus={false}><img alt="" src={require("assets/icon/pencil.jpg")} /></InputWithMaskPhone>
                            </li>
                        }

                        <div className={classes.third__block__about}>
                            <TitleMain text={"Документы мероприятия"} />
                            {/* <span className={classes.docum__text__style}>Документы мероприятия</span> */}
                            <div className={classes.doc__main__block}>
                                <div className={classes.document__style}>
                                    <a onClick={() => window.open("https://disk.yandex.ru/i/yJhf7Z6jpE-WUQ")}>
                                        <FcDocument className={classes.icon__style} />
                                        <span>Для несовершен. №1</span>
                                    </a>
                                </div>

                                <div className={classes.document__style}>
                                    <a onClick={() => window.open("https://disk.yandex.ru/i/9TCAGhK9oZUkIw")}>
                                        <FcDocument className={classes.icon__style} />
                                        <span>Для несовершен. №2</span>
                                    </a>
                                </div>

                                <div className={classes.document__style}>
                                    <a onClick={() => window.open("https://disk.yandex.ru/i/nBNte-SHS2nZGw")}>
                                        <FcDocument className={classes.icon__style} />
                                        <span>Для совершен №1.</span>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <FormControl component="fieldset" >
                            {/* <FormControlLabel
                                value="end"
                                className={userInfo.check_politics_1 == true ? classes.span__replace__color__m : classes.span__replace__color}
                                control={<Checkbox className={classes.span__replace__color} onChange={event => setUserInfo(old => ({ ...old, check_politics_1: event.target.checked }))} checked={userInfo.check_politics_1} />}
                                label={
                                    <p className={classes.span__replace__color__main}>
                                        Принимаю{' '}
                                        <a className={classes.style__link__access} onClick={() => window.open("https://disk.yandex.ru/i/zIOT9ZMqzWHbkw")}>пользовательское соглашение положения о хакатоне</a>
                                    </p>
                                }
                                labelPlacement="end"
                            /> */}
                            {/* <FormControlLabel
                                value="end"
                                className={userInfo.check_politics_2 == true ? classes.span__replace__color__m : classes.span__replace__color}
                                control={<Checkbox className={classes.span__replace__color} onChange={event => setUserInfo(old => ({ ...old, check_politics_2: event.target.checked }))} checked={userInfo.check_politics_2} />}
                                label={
                                    <p className={classes.span__replace__color__main}>
                                        Принимаю{' '}
                                        <a className={classes.style__link__access} onClick={() => window.open("https://disk.yandex.ru/i/k4Z_6sV_4uPMYA")}>порядок обработки персональных данных участников мероприятия</a>

                                    </p>
                                }
                                labelPlacement="end"
                            /> */}
                            {/* <FormControlLabel
                                value="end"
                                className={userInfo.check_politics_3 == true ? classes.span__replace__color__m : classes.span__replace__color}
                                control={<Checkbox className={classes.span__replace__color} onChange={event => setUserInfo(old => ({ ...old, check_politics_3: event.target.checked }))} checked={userInfo.check_politics_3} />}
                                label={
                                    <p className={classes.span__replace__color__main}>
                                        Принимаю{' '}
                                        <a className={classes.style__link__access} onClick={() => window.open("https://disk.yandex.ru/i/nSvsXu96gEE5CQ")}>согласие на обработку персональных данных участников мероприятия</a>

                                    </p>
                                }
                                labelPlacement="end"
                            /> */}
                            {/* <FormControlLabel
                                value="end"
                                className={userInfo.check_politics_4 == true ? classes.span__replace__color__m : classes.span__replace__color}
                                control={<Checkbox className={classes.span__replace__color} onChange={event => setUserInfo(old => ({ ...old, check_politics_4: event.target.checked }))} checked={userInfo.check_politics_4} />}
                                label={
                                    <p className={classes.span__replace__color__main}>
                                        Принимаю{' '}
                                        <a className={classes.style__link__access} onClick={() => window.open("https://disk.yandex.ru/i/uUce2oW9qehn9w")}>согласие на обработку персональных данных участников мероприятия </a>
                                    </p>
                                }
                                labelPlacement="end"
                            /> */}
                            <FormGroup aria-label="position" row>
                                <FormControlLabel
                                    value="end"
                                    className={userInfo.check_politics == true ? classes.span__replace__color__m : classes.span__replace__color}
                                    control={<Checkbox className={classes.span__replace__color} onChange={event => setUserInfo(old => ({ ...old, check_politics: event.target.checked }))} checked={userInfo.check_politics} />}
                                    label={
                                        <p className={classes.span__replace__color__main}>
                                            Подтверждаю корректность введенных данных, принимаю{' '}
                                            <a className={classes.style__link__access} onClick={() => window.open(`${env.nodeHost}/terms`)}>пользовательское соглашение</a>, даю {' '}
                                            <a className={classes.style__link__access} onClick={() => window.open("https://disk.yandex.ru/i/Ljr3iGpaSlKbTw")}>согласие на обработку персональных данных</a>

                                        </p>
                                    }
                                    labelPlacement="end"
                                />

                            </FormGroup>
                        </FormControl>



                    </ul>

                    <div className={classes.button__style__center}>
                        <Button onClick={() => updateAccount(userInfo)}>Сохранить</Button>
                    </div>

                    <Notifications />
                </div>
            </Loader>
        </>
    )
}

export default Profile