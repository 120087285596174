import Button from "components/UI/buttons/button/Button";
import ContentContainer from "components/UI/contentContainer/ContentContainer"
// import Button
import classes from './OccasionContainer.module.css'
import { publickRoutNames } from "router";
import { useNavigate } from "react-router-dom";

const OccasionContainer = ({ participantPopUp, ...props }) => {

    const navigate = useNavigate()
    const date = new Date(props?.date);
    return (
        <ContentContainer>
            <div className={classes.occasion}>
                <div className={classes.occasion__title}>
                    <span>{props.name}</span>
                </div>
                <span className={classes.line}></span>
                <div className={classes.occasion__date}>
                    <span>{date.getDate().toString().length === 1 ? "0" + date.getDate().toString() : date.getDate()}.{(date.getMonth() + 1).toString().length === 1 ? "0" + (date.getMonth() + 1).toString() : date.getMonth() + 1}.{date.getFullYear()}</span>
                </div>
                <span className={classes.line}></span>
                <div className={classes.occasion__time}>
                    <span>{props.time.toString().slice(0, 5)}</span>
                </div>
                <span className={classes.line}></span>
                <div className={classes.occasion__place}>
                    <span>{props.place}</span>
                </div>
                <div className={classes.occasion__buttin}>
                    <Button onClick={() => navigate(`/${publickRoutNames.aboutEvent}`, { state: { id: props.id } })}>Подробней</Button>
                    {props?.qr_code && <Button onClick={() => window.open(props?.qr_code, "_self")}>Создать команду</Button>}
                    <Button onClick={() => participantPopUp(props.id, 'participate')}>Участвовать</Button>
                </div>
            </div>
        </ContentContainer>
    );
}

export default OccasionContainer;