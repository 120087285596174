import './Sidebar.css'
import { useCallback, useEffect, useRef, useState } from 'react';
import { sidebarRoutes, sidebarAuthRoutes } from 'router/sidebar'
import "@fontsource/roboto"
import { FiMenu, FiX } from "react-icons/fi";
import ItemWithIcon from './ItemWithIcon';
import { useDispatch, useSelector } from 'react-redux';
import { setSidebarStatus } from 'redux/slice/sidebarSlice'
import { useDeviceType } from 'components/hooks/useDeviceType';
import { useLocation } from 'react-router-dom';
import OAuthService from 'services/OAuthService';


const Sidebar = () => {
    const [links, setLinks] = useState([])
    const refToggle = useRef(null)
    const refNavigation = useRef(null)
    const listRef = useRef(null)
    const isOpen = useSelector(state => state.sidebar.isActive)
    const sidebarItemName = useSelector(state => state.sidebarItem.item)

    const dispatch = useDispatch()


    function openAndCloseSidebar() {
        const toggleClassList = refToggle.current.classList
        const navigationClassList = refNavigation.current.classList
        if (isOpen) {
            toggleClassList.add('active')
            navigationClassList.add('active')
        }
        else {
            toggleClassList.remove('active')
            navigationClassList.remove('active')
        }
    }

    useEffect(() => {
        openAndCloseSidebar()
    }, [isOpen])

    const activate = useCallback(() => {
        dispatch(setSidebarStatus(!isOpen))
    }, [dispatch, isOpen])


    function updateLinks() {
        const userInfo = OAuthService.decryptedSync('userData')
        const { command } = OAuthService.decryptedSync('command')
        setLinks(sidebarRoutes.map((item, i) => {
            const canAccess =
                (item.rights.private === false) ||
                (item.rights.private === true && userInfo?.user) ||
                (item.rights.command === true && command) ||
                (item.rights.jury === true && userInfo?.part?.id > 2) ||
                (item.rights.admin === true && (userInfo?.part?.id > 4 || userInfo?.superUser === true))
            return canAccess ? (
                <ItemWithIcon key={i} title={item?.title} Icon={item?.icon} name={item.name}>
                    {item.title}
                </ItemWithIcon>
            ) : null;
        }));
    }


    useEffect(() => {
        updateLinks()
    }, [localStorage.getItem('userData'), localStorage.getItem('command'), sidebarItemName])

    useEffect(() => {
        const list = listRef?.current?.children
        for (let i = 0; i < list.length; i++) {
            list[i].onclick = function () {
                let j = 0;
                while (j < list.length) {
                    list[j++].className = 'sidebar-list';
                }
                try {
                    list[i].className = 'sidebar-list active';
                } catch (error) {
                    // console.log('sidebarError :', error);
                }
            }
        }
    }, [listRef?.current?.children])

    const device = useDeviceType()
    const location = useLocation()
    const [innerWidth, setInnerWidth] = useState(window.innerWidth)
    const navBarPaths = ['admin', 'profile', 'team-grade-page', 'main']

    const authItem = (i) => <ItemWithIcon onClick={updateLinks} newPageTitle={sidebarAuthRoutes[i]?.newPageTitle} title={sidebarAuthRoutes[i]?.title} Icon={sidebarAuthRoutes[i]?.icon} name={sidebarAuthRoutes[i].name}>{sidebarAuthRoutes[i].title}</ItemWithIcon>

    const getSidebarWidth = () => {
        if (device === 'phone') {
            if (navBarPaths.some(element => location.pathname.includes(element))) {
                return 'calc(var(--navbar-height) + 3px)'
            } else {
                return '4vw'
            }
        } else if (device === 'table') {
            return '9.795vw'
        } else {
            if (innerWidth > 1281) {
                return '0.795vw'
            }
            return '6.795vw'
        }
    }

    useEffect(() => {
        const handleResize = () => setInnerWidth(window.innerWidth)
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    return (
        <aside className='sidebarNavigation' ref={refNavigation}>
            <div className="sidebarIcon">
                <img src={require("assets/icon/logo.png")} />
            </div>
            <div className="toggle" ref={refToggle} onClick={activate} style={{
                top: getSidebarWidth()
            }}>
                {isOpen ? <FiX className="close toggleButton" />
                    : <FiMenu className="open toggleButton" />}
            </div>
            <ul ref={listRef}>
                {links}
                {localStorage.getItem('userData') ? authItem(1) : authItem(0)}
            </ul>
        </aside>
    );
}

export default Sidebar;
