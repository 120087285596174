import classes from "./СonfirmationParticipation.module.css"
import IconWithText from "components/UI/iconWithText/IconWithText"
import InputBlock from "components/UI/inputBlock/InputBlock"
import Button from "components/UI/buttons/button/Button"
import "@fontsource/roboto"
import Tooltip from "rc-tooltip"
import 'rc-tooltip/assets/bootstrap_white.css';
import { useDeviceType } from "components/hooks/useDeviceType"
import { useRef, useState } from "react"
import TitleMain from "../titleMain/TitleMain"

const СonfirmationParticipation = ({ userInfo, createCommand, close,
    commandData, setCommandData, role = [] }) => {
    const device = useDeviceType()

    const [roleFilter, setRoleFilter] = useState(role)
    const [currentRole, setCurrentRole] = useState(null)
    const [currentRoleText, setCurrentRoleText] = useState('')

    const ref = useRef()

    const changeRole = (data, index) => {
        data.length === 0 ? setRoleFilter(role) : setRoleFilter(role.filter(item => item.role_name.toLowerCase().includes(data.toLowerCase())))
        setCurrentRoleText(data)
        if (index !== undefined) {
            setCurrentRole(roleFilter[index])
            setCurrentRoleText(roleFilter[index].role_name)
        }
    }


    return (
        <div className={classes.main__block__ticket__style} onClick={null} ref={ref}>
            <TitleMain text={"Создание команды"} jsc="center" />
            <ul>
                {roleFilter.length > 0 && device === "phone"
                    ? <li>
                        <InputBlock refMainContainer={ref} setValue={changeRole} indexStatus={true} onListStatus={true} list={roleFilter.map(item => item.role_name)}
                            value={currentRoleText} placeholder={"Роль в команде"}><img src={require("assets/images/arrow.jpg")} /></InputBlock>
                    </li>
                    :
                    <li>
                        <InputBlock placeholder={"Роль в команде"} refMainContainer={ref} setValue={changeRole} width={33} indexStatus={true} onListStatus={true} list={roleFilter.map(item => item.role_name)}
                            value={currentRoleText}><img src={require("assets/images/arrow.jpg")} /></InputBlock>
                    </li>
                }

                {device === "phone"
                    ? <li>
                        <InputBlock setValue={char => setCommandData(old => ({ ...old, name: char }))} value={commandData.name} placeholder={"Команда"} nListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                    </li>
                    :
                    <li>
                        <InputBlock placeholder={"Название команды"} setValue={char => setCommandData(old => ({ ...old, name: char }))} value={commandData.name} localRef={true} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                    </li>
                }

                {device === "phone"
                    ? <li>

                        <InputBlock setValue={char => setCommandData(old => ({ ...old, count_participant: char }))} value={commandData.count_participant} placeholder={"Кол-во людей в команде"} nListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                    </li>
                    :
                    <li>
                        <InputBlock placeholder={"Кол-во людей в команде"} setValue={char => setCommandData(old => ({ ...old, count_participant: char }))} value={commandData.count_participant} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                    </li>
                }

            </ul>
            <div style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center", marginTop: "1vw" }}>
                <Button onClick={close}>Закрыть</Button>
                <Button onClick={() => createCommand(commandData, currentRole)}>Подтвердить</Button>
            </div>

        </div>
    );
}

export default СonfirmationParticipation;