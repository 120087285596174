import classes from "./CreateAdmin.module.css"
import "@fontsource/roboto"
import InputBlock from "components/UI/inputBlock/InputBlock"
import { useState } from 'react'
import Button from "components/UI/buttons/button/Button"
import Tooltip from "rc-tooltip"
import 'rc-tooltip/assets/bootstrap_white.css';
import { useDeviceType } from "components/hooks/useDeviceType"
import OccasionService from "services/OccasionService"
import PartService from "services/PartService"
import ParticipantService from "services/ParticipantService"
import NewsService from "services/NewsService"
import { getOccasionStatusByDescription, getOccasionStatusDescriptionList } from "model/occasionStatus"
import Loader from "components/UI/loader/Loader"
import TitleMain from "components/UI/titleMain/TitleMain";

const CreateAdmin = ({
    occasions, occasionList,
    parts, partList, updateOccasionStatus,
    participant, participantList, updateParticipant,
    updateOccasionTaskStatus, occasionTaskList,
    news, newList, updateNews, fetchingQRcode,
    occasionTaskListSelectable, updateOccasionTaskSelectable,
}) => {
    const device = useDeviceType()

    const [currentOccasionQRG, setCurrentOccasionQRG] = useState({})
    const [currentOccasionTask, setCurrentOccasionTask] = useState({})
    const [currentOccasionTaskSelectable, setCurrentOccasionTaskSelectable] = useState({})
    const [currentOccasion, setCurrentOccasion] = useState({})
    const [currentPart, setCurrentPart] = useState({})
    const [currentParticipant, setCurrentParticipant] = useState({})
    const [currentNews, setCurrentNews] = useState({})

    const [indexOccasionTaskSelectable, setIndexOccasionTaskSelectable] = useState(0)
    const [indexOccasionTask, setIndexOccasionTask] = useState(0)
    const [indexOccasion, setIndexOccasion] = useState(0)
    const [indexNews, setIndexNews] = useState(0)
    const [indexParticipant, setIndexParticipant] = useState(0)

    const [occasionStatus, setOccasionStatus] = useState({ description: '', id: '' })


    function changeOcassionTaskSelectable(data, index) {
        setCurrentOccasionTaskSelectable(occasions[index])
        setIndexOccasionTaskSelectable(index)
    }
    function changeOcassionTask(data, index) {
        setCurrentOccasionTask(occasions[index])
        setIndexOccasionTask(index)
    }
    function changeOcassionQRG(data, index) {
        setCurrentOccasionQRG(occasions[index])
    }
    function changeOcassion(data, index) {
        setCurrentOccasion(occasions[index])
        setIndexOccasion(index)
    }
    function changePart(data, index) {
        setCurrentPart(parts[index])
    }
    function changeParticipant(data, index) {
        setCurrentParticipant(participant[index])
        setIndexParticipant(index)
    }
    function changeNews(data, index) {
        setCurrentNews(news[index])
        setIndexNews(index)
    }

    function changeOccasionTaskSelectable() {
        currentOccasionTaskSelectable.task_selectable = !currentOccasionTaskSelectable.task_selectable
        updateOccasionTaskSelectable(currentOccasionTaskSelectable, indexOccasionTaskSelectable)
    }
    function changeOccasionTaskStatus() {
        currentOccasionTask.on_task_publish = !currentOccasionTask.on_task_publish
        updateOccasionTaskStatus(currentOccasionTask, indexOccasionTask)
    }
    function qrCodeGeneration() {
        fetchingQRcode(currentOccasionQRG)
    }
    function changeNewsStatus() {
        currentNews.status = !currentNews.status
        updateNews(currentNews, indexNews)
    }
    function changeParticipantPart() {
        currentParticipant.part = currentPart.name
        updateParticipant(currentParticipant, indexParticipant, currentPart.id)
    }
    function changeOccasionStatus(data, index) {
        const id = getOccasionStatusByDescription(data)
        setOccasionStatus({ description: data, id })
    }

    function pushChangeOccasionStatus() {
        (occasionStatus?.id?.id > 0 && currentOccasion.id > 0) && updateOccasionStatus(currentOccasion.id, occasionStatus.id.id)

    }


    return (
        <Loader>
            <div className={classes.events__create__style} >
                <TitleMain text={"Администрирование"} jsc="center" fns="1.5vw" elpSize="0.9vw" />
                <ul>
                    <div className={classes.shadow__box__style}>
                        {(occasions?.length >= 1) && ((device === "phone")
                            ? <li>
                                <InputBlock localRef={true} readOnly={true} indexStatus={true} placeholder={"Активация кейсов"} onListStatus={true} list={occasionTaskList} value={OccasionService.getTextInputOccasionTask(currentOccasionTask)} setValue={changeOcassionTask}><img src={require("assets/images/arrow.jpg")} /></InputBlock>
                                {Object.keys(currentOccasionTask).length > 0 &&
                                    <div className={classes.btn__style}>
                                        <Button onClick={changeOccasionTaskStatus}>{OccasionService.getStatusTextButtonTaskOccasion(currentOccasionTask.on_task_publish, currentOccasionTask)}</Button>
                                    </div>
                                }
                            </li>
                            : <li>
                                <InputBlock placeholder={"Кейсы"} localRef={true} readOnly={true} indexStatus={true} onListStatus={true} list={occasionTaskList} value={OccasionService.getTextInputOccasionTask(currentOccasionTask)} setValue={changeOcassionTask} ><img src={require("assets/images/arrow.jpg")} /></InputBlock>
                                {Object.keys(currentOccasionTask).length > 0 &&
                                    <div className={classes.btn__style}>
                                        <Button onClick={changeOccasionTaskStatus}>{OccasionService.getStatusTextButtonTaskOccasion(currentOccasionTask.on_task_publish, currentOccasionTask)}</Button>
                                    </div>
                                }
                            </li>
                        )}
                    </div>
                    <div className={classes.shadow__box__style}>
                        {(occasions?.length >= 1) && ((device === "phone")
                            ? <li>
                                <InputBlock localRef={true} readOnly={true} indexStatus={true} placeholder={"Разрешить выбирать кейсы"} onListStatus={true} list={occasionTaskListSelectable} value={OccasionService.getTextInputOccasionTask(currentOccasionTaskSelectable)} setValue={changeOcassionTaskSelectable}>
                                    <img src={require("assets/images/arrow.jpg")} />
                                </InputBlock>
                                {Object.keys(currentOccasionTaskSelectable).length > 0 &&
                                    <div className={classes.btn__style}>
                                        <Button onClick={changeOccasionTaskSelectable}>{OccasionService.getTextInputOccasionTaskSelectable(currentOccasionTaskSelectable.task_selectable, currentOccasionTaskSelectable)}</Button>
                                    </div>
                                }
                            </li>
                            : <li>
                                <InputBlock placeholder={"Разрешить выбирать кейсы"} localRef={true} readOnly={true} indexStatus={true} onListStatus={true} list={occasionTaskListSelectable} value={OccasionService.getTextInputOccasionTaskSelectable(currentOccasionTaskSelectable)} setValue={changeOcassionTaskSelectable} >
                                    <img src={require("assets/images/arrow.jpg")} /></InputBlock>
                                {Object.keys(currentOccasionTaskSelectable).length > 0 &&
                                    <div className={classes.btn__style}>
                                        <Button onClick={changeOccasionTaskSelectable}>{OccasionService.getStatusTextButtonTaskSelectableOccasion(currentOccasionTaskSelectable.task_selectable, currentOccasionTaskSelectable)}</Button>
                                    </div>
                                }
                            </li>
                        )}
                    </div>
                    <div className={classes.shadow__box__style}>
                        {(parts?.length >= 1 && participant.length >= 1) &&
                            (device === "phone"
                                ? <li>
                                    <InputBlock readOnly={true} localRef={true} indexStatus={true} placeholder={"Выбор пользователя"} onListStatus={true} list={participantList} width={45} value={ParticipantService.getTextInputParticipant(currentParticipant)} setValue={changeParticipant} ><img src={require("assets/images/arrow.jpg")} /></InputBlock>

                                    <div className={classes.btn__style}>
                                        <InputBlock readOnly={true} localRef={true} indexStatus={true} placeholder={"Выбор статуса"} onListStatus={true} list={partList} width={14} value={PartService.getTextInputPart(currentPart)} setValue={changePart} ><img src={require("assets/images/arrow.jpg")} /></InputBlock>
                                    </div>

                                    <div className={classes.btn__style}>
                                        <Button onClick={changeParticipantPart}>Назначить</Button>
                                    </div>
                                </li>
                                : <li>
                                    <InputBlock placeholder={"Участник"} localRef={true} readOnly={true} indexStatus={true} onListStatus={true} list={participantList} width={45} value={ParticipantService.getTextInputParticipant(currentParticipant)} setValue={changeParticipant} ><img src={require("assets/images/arrow.jpg")} /></InputBlock>

                                    <div className={classes.btn__style}>
                                        <InputBlock placeholder={"Статус"} localRef={true} readOnly={true} indexStatus={true} className={classes.input__custom__style} onListStatus={true} list={partList} width={14} value={PartService.getTextInputPart(currentPart)} setValue={changePart} ><img src={require("assets/images/arrow.jpg")} /></InputBlock>
                                    </div>
                                    <div className={classes.btn__style}>
                                        <Button onClick={changeParticipantPart}>Назначить</Button>
                                    </div>
                                </li>
                            )}
                    </div>
                    {(news?.length >= 1) &&
                        <div className={classes.shadow__box__style}>
                            {device === "phone"
                                ? <li>
                                    <InputBlock localRef={true} placeholder={"Активация новостей"} indexStatus={true} readOnly={true} onListStatus={true} list={newList} value={NewsService.getTextInputNews(currentNews)} setValue={changeNews} ><img src={require("assets/images/arrow.jpg")} /></InputBlock>
                                    <div className={classes.btn__style}>
                                        {Object.keys(currentNews).length > 0 &&
                                            <Button onClick={changeNewsStatus}>{NewsService.getStatusTextButtonNews(currentNews.status, currentNews)}</Button>
                                        }
                                    </div>
                                </li>
                                : <li>
                                    <InputBlock placeholder={"Новость"} localRef={true} onListStatus={true} indexStatus={true} readOnly={true} list={newList} value={NewsService.getTextInputNews(currentNews)} setValue={changeNews} ><img src={require("assets/images/arrow.jpg")} /></InputBlock>
                                    <div className={classes.btn__style}>
                                        {Object.keys(currentNews).length > 0 &&
                                            <Button onClick={changeNewsStatus}>{NewsService.getStatusTextButtonNews(currentNews.status, currentNews)}</Button>
                                        }
                                    </div>
                                </li>
                            }
                        </div>
                    }
                    <div className={classes.shadow__box__style}>
                        {(device === "phone"
                            ? <li>
                                <InputBlock width={45} readOnly={true} indexStatus={true} placeholder={"Активация мероприятий"} onListStatus={true} list={occasionList} value={OccasionService.getTextInputOccasion(currentOccasion)} setValue={changeOcassion} ><img src={require("assets/images/arrow.jpg")} /></InputBlock>

                                <div className={classes.btn__style}>
                                    <InputBlock readOnly={true} localRef={true} indexStatus={true} placeholder={"Выбор статуса"} onListStatus={true} list={getOccasionStatusDescriptionList()} width={14} value={occasionStatus.description} setValue={changeOccasionStatus} ><img src={require("assets/images/arrow.jpg")} /></InputBlock>
                                </div>

                                <div className={classes.btn__style}>
                                    <Button onClick={pushChangeOccasionStatus}>Применить</Button>
                                </div>
                            </li>
                            : <li>
                                <InputBlock placeholder={"Мероприятие"} width={45} readOnly={true} indexStatus={true} onListStatus={true} list={occasionList} value={OccasionService.getTextInputOccasion(currentOccasion)} setValue={changeOcassion} ><img src={require("assets/images/arrow.jpg")} /></InputBlock>

                                <div className={classes.btn__style}>
                                    <InputBlock placeholder={"Статус"} localRef={true} readOnly={true} indexStatus={true} className={classes.input__custom__style} onListStatus={true} list={getOccasionStatusDescriptionList()} width={14} value={occasionStatus.description} setValue={changeOccasionStatus} ><img src={require("assets/images/arrow.jpg")} /></InputBlock>
                                </div>

                                <div className={classes.btn__style}>
                                    <Button onClick={pushChangeOccasionStatus}>Применить</Button>
                                </div>
                            </li>
                        )}
                    </div>

                    <div className={classes.shadow__box__style}>
                        {(occasions?.length >= 1) && ((device === "phone")
                            ? <li>
                                <InputBlock readOnly={true} indexStatus={true} placeholder={"Генерация qr-code"} onListStatus={true} list={occasionList} value={OccasionService.getTextInputOccasionQRG(currentOccasionQRG)} setValue={changeOcassionQRG} ><img src={require("assets/images/arrow.jpg")} /></InputBlock>
                                {Object.keys(currentOccasionQRG).length > 0 &&
                                    <div className={classes.btn__style}>
                                        <Button onClick={qrCodeGeneration}>Создать</Button>
                                    </div>
                                }
                            </li>
                            : <li>
                                <InputBlock placeholder={"Генерация QR"} readOnly={true} indexStatus={true} onListStatus={true} list={occasionList} value={OccasionService.getTextInputOccasionQRG(currentOccasionQRG)} setValue={changeOcassionQRG} ><img src={require("assets/images/arrow.jpg")} /></InputBlock>
                                {Object.keys(currentOccasionQRG).length > 0 &&
                                    <div className={classes.btn__style}>
                                        <Button onClick={qrCodeGeneration}>Создать</Button>
                                    </div>
                                }
                            </li>
                        )}
                    </div>
                </ul>

            </div >
        </Loader>
    )
}

export default CreateAdmin