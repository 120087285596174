import classes from "./CardBlockFirst.module.css"
import "@fontsource/roboto"
import Loader from "components/UI/loader/Loader"
import Edro from "assets/images/companyOriginal/edroL.svg"
import Gaz from "assets/images/company/informgaz.png"
import Art from "assets/images/company/artlab.png"
// import Adm from "assets/images/company/hmao.png"
import Adm from "assets/images/companyOriginal/hmao.svg"
import Kuchin from "assets/images/company/kuchin.png"

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/effect-cards';
import 'styles/css/card1.css';
import { Autoplay, EffectCards } from 'swiper/modules';

const CardBlockFirst = ({ onClick }) => {


    return (
        <Loader>
            <div className={classes.main__block__wrapper}>
                <Swiper
                    effect={'cards'}
                    grabCursor={true}

                    autoplay={{
                        delay: 1500,
                        disableOnInteraction: false,
                    }}


                    modules={[Autoplay, EffectCards]}
                    className="mySwiper"
                >
                    <SwiperSlide onClick={() => window.open("https://edro.su/")}>
                        <div >
                            <img src={Edro} alt="" className={classes.img__style} />
                            <p className={classes.text__style}>
                                ЕДРО<br />ТЕХНОЛОГИИ
                            </p>
                            <p className={classes.description__style}>
                                Создан помогать
                            </p>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide onClick={() => window.open("https://inform.gazprom.ru/")}>
                        <div>
                            <img src={Gaz} alt="" className={classes.img__style} />
                            <p className={classes.text__style}>
                                Газпром информ
                            </p>
                            <p className={classes.description__style}>
                                Мечты сбываются
                            </p>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide onClick={() => window.open("https://vk.com/artlab_surgu")}>
                        <div>
                            <img src={Art} alt="" className={classes.img__style} />
                            <p className={classes.text__style}>
                                ARTLAB_SurGU
                            </p>
                            <p className={classes.description__style}>
                                Лаборатория<br />
                                научно-технического<br />
                                творчества
                            </p>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide onClick={() => window.open("https://admhmansy.ru/news/480/177934/")}>
                        <div>
                            <img src={Adm} alt="" className={classes.img__style} />
                            <p className={classes.text__style}>
                                Правительство
                            </p>
                            <p className={classes.description__style}>
                                Ханты-Мансийского<br />автономного округа - Югры
                            </p>
                        </div>
                    </SwiperSlide>
                    {/* <SwiperSlide onClick={() => window.open("https://int-club.ru/")}>
                        <div>
                            <img src={Kuchin} alt="" className={classes.img__style} />
                            <p className={classes.text__style}>
                                Интеллектуальный<br />клуб
                            </p>
                            <p className={classes.description__style}>
                                ЧТО НАША ЖИЗНЬ?<br />ИГРА!
                            </p>
                        </div>
                    </SwiperSlide> */}
                </Swiper>
            </div>
        </Loader>
    )
}

export default CardBlockFirst