import Strings from "values/Strings";
import { MdEventAvailable } from 'react-icons/md';
import { IoDocumentAttachOutline } from 'react-icons/io5';
import { BiNews } from 'react-icons/bi';
import { IoAccessibilityOutline } from 'react-icons/io5';
import { MdOutlineCases } from 'react-icons/md';
import { AiOutlineSafetyCertificate } from 'react-icons/ai';
import { RiTeamLine } from 'react-icons/ri';
import { navBareRoute } from "router";

const superUser = false

const navbarAdminRoutes = [
    { name: `${navBareRoute.admin}/create-event`, title: Strings.navbarCreate, icon: MdEventAvailable, superUser },

    { name: `${navBareRoute.admin}/create-news`, title: Strings.navbarNews, icon: BiNews, superUser },
    { name: `${navBareRoute.admin}/administration`, title: Strings.navbarAdministration, icon: IoAccessibilityOutline, superUser },
    { name: `${navBareRoute.admin}/work-with-case`, title: Strings.navbarWorkOnCase, icon: MdOutlineCases, superUser },
    { name: `${navBareRoute.admin}/certificates`, title: Strings.navbarCertificate, icon: AiOutlineSafetyCertificate, superUser },
    { name: `${navBareRoute.admin}/team-grade`, title: Strings.navbarGrade, icon: RiTeamLine, superUser },
]

export default navbarAdminRoutes
