import './Sidebar.css'
import { useEffect, useRef } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { setSidebarStatus } from 'redux/slice/sidebarSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useDeviceType } from 'components/hooks/useDeviceType';
import { newName } from 'redux/slice/titleSlice';
import { changePopUpWindowStatus } from 'redux/slice/popUpWindowSlice';
import Strings from 'values/Strings';
import { setSidebarItem } from 'redux/slice/sidebarItemSlice';

function ItemWithIcon({ Icon, onClick, title, name, newPageTitle, ...ptops }) {
    const location = useLocation()
    const ref = useRef(null)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const device = useDeviceType()
    const itemName = useSelector(state => state.sidebarItem.item)

    function closeSideBar() {
        dispatch(setSidebarStatus(false))
    }

    useEffect(() => {
        if (title === itemName) { ref.current.className = 'sidebar-list active' }
        else { ref.current.className = 'sidebar-list' }
    }, [location, itemName])


    function navigation() {
        dispatch(changePopUpWindowStatus(false))
        if (itemName !== title) {
            dispatch(setSidebarItem(title))
        }
        newPageTitle ? dispatch(newName(newPageTitle)) : (title !== Strings.teamRoom && dispatch(newName(title)))
        navigate(`/${name}`);
        (device !== 'desktop' || window.innerWidth <= 1281) && closeSideBar()
        onClick && onClick()
    }


    return (
        <li className="sidebar-list" ref={ref}>
            <a onClick={navigation} className="nav-link" exact="true">
                <div className='sidebar-list__container'>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                        <div className="sidebar-icon">{<Icon className={'ionIcon'} />}</div>
                        <div className="sidebar-title">{title}</div>
                    </div>
                </div>
            </a>
        </li>
    );
}

export default ItemWithIcon;