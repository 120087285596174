import classes from './InputTag.module.css'
import { useRef, useEffect } from 'react'
import { NotificationManager } from "react-notifications"
import Strings from 'values/Strings'


function InputTag({ tags, setTags, placeholder, keys = [] }) {

    const refLabel = useRef(null)

    function handleKeyDown(e) {
        if (e.key !== 'Enter') return
        const value = e.target.value
        if (!value.trim()) return
        if (value.includes("|")) {
            const values = value.split('|')
            const item = Object.fromEntries(keys.map((key, index) => [key, values[index]]))
            setTags([...tags, item])
        } else {
            if (keys.length > 0) {
                NotificationManager.error(`Необходимо заполнить по шаблону: ${keys.join('|')}`, Strings.notificationsErrorTitle, 5000);
            }
            setTags([...tags, value])
        }
        e.target.value = ''
    }

    function removeTag(index) {
        setTags(tags.filter((el, i) => i !== index))
    }

    useEffect(() => {
        if (refLabel.current && refLabel.current.className) {
            if (tags.length > 0) {
                refLabel.current.className = classes.inputBlock__lable__focus
            }
        }
        // refLabel && refLabel?.current?.className && changeBlureInput()
    }, [refLabel, tags])

    function changeFocusInput() {
        refLabel.current.className = classes.inputBlock__lable__focus
    }

    function changeBlureInput() {
        if (tags.length === 0) {
            refLabel.current.className = classes.inputBlock__lable
        }
    }

    return (
        <div onFocus={changeFocusInput} onBlur={changeBlureInput} className={classes.inputTag__wrapper}>
            <label ref={refLabel} className={classes.inputBlock__lable}>{placeholder}</label>
            <div className={classes.tags_input_container} >
                {
                    tags && tags.map((item, index) => {
                        return (
                            <div className={classes.tag_item} key={index}>
                                <div className={classes.tag_itemContainer}>
                                    {
                                        typeof item === 'object' ? <span className={classes.text}>{Object.values(item).join(' - ')}</span>
                                            : <span className={classes.text}>{item}</span>
                                    }
                                    <span className={classes.close} onClick={() => removeTag(index)}>&times;</span>
                                </div>
                            </div>
                        )
                    })
                }
                <input onKeyDown={handleKeyDown} type="text" className={classes.tags_input} />
            </div >
        </div>
    )
}

export default InputTag;