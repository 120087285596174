import classes from "./TicketParticipation.module.css"
import IconWithText from "components/UI/iconWithText/IconWithText"
import InputBlock from "components/UI/inputBlock/InputBlock"
import Button from "components/UI/buttons/button/Button"
import "@fontsource/roboto"
import Tooltip from "rc-tooltip"
import 'rc-tooltip/assets/bootstrap_white.css';
import { useDeviceType } from "components/hooks/useDeviceType"
import { useRef, useState } from "react"
import TitleMain from "../titleMain/TitleMain"

function TicketParticipation({ close, userInfo, preRegistration, action, role = [], commandName = '' }) {
    const device = useDeviceType()

    const [roleFilter, setRoleFilter] = useState(role)
    const [currentRole, setCurrentRole] = useState(null)
    const [currentRoleText, setCurrentRoleText] = useState('')

    const ref = useRef()

    const changeRole = (data, index) => {
        data.length === 0 ? setRoleFilter(role) : setRoleFilter(role.filter(item => item.role_name.toLowerCase().includes(data.toLowerCase())))
        setCurrentRoleText(data)
        if (index !== undefined) {
            setCurrentRole(roleFilter[index])
            setCurrentRoleText(roleFilter[index].role_name)
        }
    }

    return (
        <div className={classes.main__block__ticket__style} ref={ref}>
            {/* <h3>Заявка на участие</h3> */}
            <TitleMain text={action === 'commandToken' ? "Присоединиться к команде" : 'Заявка на участие'} jsc="center" />
            <ul>
                {commandName.length > 0 &&
                    (device === "phone"
                        ? <li className={classes.cursor}>
                            <InputBlock cursorPointer={true} readOnly={true} placeholder={"Команда"} value={commandName} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                        </li>
                        :
                        <li className={classes.cursor}>
                            <InputBlock placeholder={"Команда"} cursorPointer={true} readOnly={true} value={commandName} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                        </li>)
                }

                {roleFilter.length > 0 && device === "phone"
                    ? <li>
                        <InputBlock refMainContainer={ref} setValue={changeRole} indexStatus={true} onListStatus={true} list={roleFilter.map(item => item.role_name)}
                            value={currentRoleText} placeholder={"Роль"}><img src={require("assets/images/arrow.jpg")} /></InputBlock>
                    </li>
                    :
                    <li>
                        <InputBlock placeholder={"Роль"} refMainContainer={ref} setValue={changeRole} width={33} indexStatus={true} onListStatus={true} list={roleFilter.map(item => item.role_name)}
                            value={currentRoleText}><img src={require("assets/images/arrow.jpg")} /></InputBlock>
                    </li>
                }

            </ul>
            <div style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center", marginTop: "1vw" }}>
                <Button onClick={close}>Закрыть</Button>
                <Button onClick={() => preRegistration(currentRole)}>Отправить</Button>
            </div>

        </div >
    );
}

export default TicketParticipation;