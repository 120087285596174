import axios from "axios";
import env from '../../package.json'



const $api = axios.create({
    withCredentials: true,
    baseURL: env.nodeHost
})

$api.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`
    return config
})

$api.interceptors.response.use((config) => {
    return config
}, (async (error) => {
    const originalRequest = error.config;
    if (error.response) {
        if (error.response.status == 401 && error.config && !error.config._isRetry) {
            originalRequest._isRetry = true;
            try {
                const res = await axios.post(`${env.nodeHost}/api/oauth/refresh`, {}, { withCredentials: true })
                localStorage.setItem('accessToken', res.data.accessToken)
                localStorage.setItem('userData', res.data.userData)
                return $api.request(originalRequest)
            } catch (error) {
                localStorage.clear()
                // console.log('НЕ АВТОРИЗОВАН')
            }
        }
        const errorMessage = error.response.data?.message || "Неизвестная ошибка";
        throw new Error(errorMessage);
    }
    throw error;
}))

export default $api