import MainContact from "components/UI/contact/mainContakt/MainContact";
import Map from "components/UI/contact/map/Map";
import "styles/css/support.css"
import { changeLoaderStatus } from "redux/slice/loaderSlice"
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import Loader from "components/UI/loader/Loader";
import { newName } from "redux/slice/titleSlice";

import ContactCard from "components/UI/contact/contactCard/ContactCard";
import TitleMain from "components/UI/titleMain/TitleMain";


import Strings from "values/Strings";
import { setSidebarItem } from "redux/slice/sidebarItemSlice";
function SupportPage() {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(changeLoaderStatus(false))
        dispatch(newName(Strings.sidebarSupport))
    }, [dispatch])

    useEffect(() => {
        dispatch(setSidebarItem(Strings.sidebarSupport))
    }, [])

    return (
        <Loader>
            <div className="support-container">
                <MainContact />
                <div className="wrapper-support" style={{ margin: 0, marginTop: "1.5vw" }}>
                    <div className="title-main">
                        <TitleMain text={"Те, кто создает"} jsc="center" />
                    </div>
                </div>

                <div className="wrapper-support">
                    <div className="author-style">
                        <ContactCard img={require("assets/images/author/edmon.png")} fullname={"Эдмон Тунян"} post={"Разработчик"} about={"До сих пор в раздумье над фразой"} tg={"https://t.me/Edmon_Tunyan"} vk={"https://vk.com/tunyan_eg"} />
                        <ContactCard img={require("assets/images/author/rost.png")} fullname={"Ростислав Сазиков"} post={"Разработчик"} about={"Делай желаемое, не сожалей о сделанном"} tg={"https://t.me/Sazikov_RS"} vk={"https://vk.com/sazikov_rs"} />
                    </div>
                </div>

                <div className="wrapper-support" style={{ margin: 0 }}>
                    <div className="author-style">
                        <ContactCard img={require("assets/images/author/liza.png")} fullname={"Елизавета Токарева"} post={"Дизайнер"} about={"Я вообще делаю, что хочу"} tg={"https://t.me/tokareva_en"} vk={"https://vk.com/tokareva_en"} />
                    </div>
                </div>

                <Map />
            </div>
        </Loader>
    );
}

export default SupportPage;